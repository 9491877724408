import { createSlice } from "@reduxjs/toolkit";

const vaStateSlice = createSlice({
  name: "vaStateStore",
  initialState: {
    vaState: undefined,
    hasHeaderLoaded: false,
    activityId: undefined,
    hideSendBox: undefined,
    flowCompleted: undefined,
    localizationTexts: {
      restart: "Restart",
    },
  },
  reducers: {
    setVaState(state, action) {
      state.vaState = action.payload;
    },
    updateVaArgs(state, action) {
      if (state.vaState) {
        state.vaState.args = action.payload;
      }
    },
    setHeaderFlag(state, action) {
      state.hasHeaderLoaded = action.payload;
    },
    setActivityId(state, action) {
      state.activityId = action.payload;
    },
    setHideSendBox(state, action) {
      state.hideSendBox = action.payload;
    },
    setLocalizationTexts(state, action) {
      state.localizationTexts = action.payload;
    },
    setFlowCompleted(state, action) {
      state.flowCompleted = action.payload;
    },
  },
});

export const vaStateSliceActions = vaStateSlice.actions;
export default vaStateSlice;
