import { configureStore } from "@reduxjs/toolkit";
import carouselSlice from "./carouselSlice";
import vaStateSlice from "./vaStateSlice";
import feedbackStateSlice from "./feedbackStateStore";
import ccStateSlice from "./ccStateSlice";
import activitySlice from "./activitySlice";

const store = configureStore({
  reducer: {
    carouselStore: carouselSlice.reducer,
    vaStateStore: vaStateSlice.reducer,
    feedbackStateStore: feedbackStateSlice.reducer,
    ccStateStore: ccStateSlice.reducer,
    activityStore: activitySlice.reducer,
  },
});
export default store;
