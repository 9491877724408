import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
const VAHome = React.lazy(() => import("./components/virtual-agent/VA-Home"));
const CCHome = React.lazy(() =>
  import("./components/compatibility-checker/CC-Home")
);
const FeedbackHome = React.lazy(() =>
  import("./components/feedback/Feedback-Home")
);
const FdSolutionWebChat = React.lazy(() =>
  import("./components/feedback/solution/FdSolution-Home")
);
const OfaSupportWebChat = React.lazy(() =>
  import("./components/virtual-agent/ofa-support/OfaSupport-Home")
);
const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<></>}>
              <VAHome />
            </React.Suspense>
          }
        />
        <Route
          path="/urcsupport.html"
          element={
            <React.Suspense fallback={<></>}>
              <VAHome />
            </React.Suspense>
          }
        />
        <Route
          path="/compatibilitychecker.html"
          element={
            <React.Suspense fallback={<></>}>
              <CCHome />
            </React.Suspense>
          }
        />
        <Route
          path="/feedback.html"
          element={
            <React.Suspense fallback={<></>}>
              <FeedbackHome />
            </React.Suspense>
          }
        />
        <Route
          path="/feedback/solution/:contextBase64"
          element={
            <React.Suspense fallback={<></>}>
              <FdSolutionWebChat />
            </React.Suspense>
          }
        />
        <Route
          path="/ofa/support/:locale"
          element={
            <React.Suspense fallback={<></>}>
              <OfaSupportWebChat />
            </React.Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
